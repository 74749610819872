import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Paper, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      paddingTop: 64, // Add padding equal to the height of the AppBar
    },
    form: {
      padding: 20,
      maxWidth: 400,
    },
    submitButton: {
      marginTop: 10,
      background: "#6415FF",
    },
  });

const Login = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setAuthState } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/token/', { username, password });
      if (response.data && response.data.access) {
        localStorage.setItem('token', response.data.access);
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          token: response.data.access,
          user: {
            username: username,
          },
        }));
        navigate('/dashboard');
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      <Paper className={styles.form}>
        <Typography variant="h5">Login</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            className={styles.submitButton}
          >
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
