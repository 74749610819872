import React, { useState, useEffect, useContext } from 'react';
import EmailEditor from 'react-email-editor';
import { Button, Container, TextField, Typography, Grid, Paper } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    templateEditorContainer: {
      flexGrow: 1,
      height: 'calc(100vh - 150px)',
      width: '100%',
    },
    editorCanvas: {
      height: '100%',
      width: '100%',
    },
    paper: {
      height: '100%',
      width: '100%',
    },
  });


const TemplateEditor = () => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const styles = useStyles()
  const [templateName, setTemplateName] = useState('');
  const emailEditorRef = React.createRef();

  useEffect(() => {
    if (!authState.token) {
      navigate('/login');
      return;
    }
  }, [authState.token, navigate]);

  const handleSaveTemplate = () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const {  html } = data;

      try {
        await axios.post(
          '/api/email-templates/',
          { name: templateName, html },
          {
            headers: { Authorization: `Bearer ${authState.token}` },
          }
        );
        alert('Template saved successfully!');
      } catch (error) {
        alert('Failed to save template. Please try again.');
      }
    });
  };

  return (
    <Container>
      <Typography variant="h4">Template Editor</Typography>
      <TextField
        label="Template Name"
        value={templateName}
        onChange={(e) => setTemplateName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Grid container className={styles.templateEditorContainer}>
        <Grid item xs={12}>
          <Paper className={styles.paper}>
            <EmailEditor
              ref={emailEditorRef}
              minHeight='800px'
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSaveTemplate}>
            Save Template
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
  
};

export default TemplateEditor;
