import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AuthProvider } from './context/AuthContext';
import { SnackbarProvider } from 'notistack';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Campaigns from './components/Campaigns';
import EmailIdentities from './components/EmailIdentities';
import ComposeEmail from './components/ComposeEmail';
import EmailTracking from './components/EmailTracking';
import Register from './components/Register';
import { WebSocketProvider } from './context/WebSocketContext';
import TemplateEditor from './components/TemplateEditor';

const useStyles = makeStyles({
  container: {
    paddingTop: 20,
  },
});

function App() {
  const classes = useStyles();

  return (
    
      <Router>
        <AuthProvider>
        <SnackbarProvider maxSnack={3}>
          <WebSocketProvider>
        <Navbar />
        <Container className={classes.container}>
        <Routes>
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/campaigns" element={<Campaigns />} />
          <Route path="/track" element={<EmailTracking />} />
          <Route path="/email-identities" element={<EmailIdentities />} />
          <Route path="/compose-email" element={<ComposeEmail />} />
          <Route path="/template-editor" element={<TemplateEditor />} />
        </Routes>
        </Container>
        </WebSocketProvider>
        </SnackbarProvider>
        </AuthProvider>
      </Router>
      
  );
}

export default App;