import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCallback } from 'react';
import WebSocketContext from '../context/WebSocketContext';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
    container: {
      marginTop: 3,
      paddingTop: 64,
    },
    paper: {
      padding: 3,
    },
    submitButton: {
      marginTop: 10,
      background: "#6415FF",
    },
  });
  
function EmailTracking() {
    const navigate = useNavigate();
    const styles = useStyles()
    const { enqueueSnackbar } = useSnackbar();
  const { authState } = useContext(AuthContext);
  const { socket } = useContext(WebSocketContext);
  const { token } = authState;
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchData = useCallback(async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get('/api/tracking/campaigns/', config);
      setCampaigns(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [token]);
  
  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    fetchData();
    const timer = setInterval(() => {
      fetchData();
    }, 5 * 60 * 1000); // Refresh every 5 minutes
  
    return () => {
      clearInterval(timer);
    };
  }, [token, navigate, fetchData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChange = (event) => {
    setSelectedCampaign(event.target.value);
  };
  const handleRefresh = () => {
    fetchData();
  };
  const selectedCampaignData = campaigns.find((campaign) => campaign.campaign_id === selectedCampaign);
  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.message) {
          enqueueSnackbar(data.message, { variant: "success" });
        }
      };
    }
    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [socket, enqueueSnackbar]);
  return (
    <Container className={styles.container}>
      <Typography variant="h4">Email Tracking</Typography>
      <FormControl fullWidth variant="outlined" sx={{ mt: 2, mb: 4 }}>
        <InputLabel id="campaign-select-label">Campaign</InputLabel>
        <Select
          labelId="campaign-select-label"
          id="campaign-select"
          value={selectedCampaign}
          onChange={handleChange}
          label="Campaign"
        >
          {campaigns.map((campaign) => (
            <MenuItem key={campaign.campaign_id} value={campaign.campaign_id}>
              {campaign.campaign_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      {selectedCampaignData && (
        <Box>
          <Button
        onClick={handleRefresh}
        startIcon={<RefreshIcon />}
        sx={{ marginLeft: 1, marginBottom: 3 }}
      >
        Refresh
      </Button>
      <TableContainer component={Paper}>
      <React.Fragment key={selectedCampaignData.campaign_id}>
            <ListItem>
              <ListItemText primary={`${selectedCampaignData.campaign_name} (${selectedCampaignData.opened_count} opened emails)`} />
            </ListItem>
            <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Sent At</TableCell>
              <TableCell>Opened At</TableCell>
              <TableCell>Opened Times</TableCell>
              <TableCell>Unsubscribed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedCampaignData && selectedCampaignData.email_activities  && (
  selectedCampaignData.email_activities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((email_activity, index) => (
    <TableRow key={index}>
                  <TableCell>{email_activity.recipient}</TableCell>
                  <TableCell>{new Date(email_activity.sent_at).toLocaleString()}</TableCell>
                  <TableCell>{new Date(email_activity.opened_at).toLocaleString()}</TableCell>
                  <TableCell>{email_activity.opened_count}</TableCell>
                  <TableCell>{email_activity.is_unsubscribed}</TableCell>
                </TableRow>
  ))
)}
</TableBody>
            </Table>
          </React.Fragment>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        component="div"
        count={selectedCampaignData.opened_count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Box>
      )}
    </Container>
  );
}

export default EmailTracking;
