import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button, MenuItem } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import WebSocketContext from '../context/WebSocketContext';




const useStyles = makeStyles({
    container: {
      marginTop: 3,
      paddingTop: 64,
    },
    paper: {
      padding: 3,
    },
    submitButton: {
      marginTop: 10,
      background: "#6415FF",
    },
  });

function ComposeEmail() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const styles = useStyles()
  const { authState } = useContext(AuthContext);
  const { socket } = useContext(WebSocketContext);
  const { token } = authState;
  const [campaigns, setCampaigns] = useState([]);
  const [identities, setIdentities] = useState([]);
  const [newEmail, setNewEmail] = useState({
    campaign: '',
    identity: '',
    subject: '',
    body_html: '',
  });
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  useEffect(() => {
    if (!token) {
        navigate('/login');
        return;
      }
  async function fetchData() {
      try {
        const campaignsResponse = await axios.get('/api/campaigns/', {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setCampaigns(campaignsResponse.data);

        const identitiesResponse = await axios.get('/api/email-identities/', {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setIdentities(identitiesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();

    async function fetchTemplates() {
      try {
        const response = await axios.get('/api/email-templates/', {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setTemplates(response.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    }
    
    fetchTemplates();
  }, [authState.token]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewEmail((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      await axios.post('/api/emails/', { ...newEmail }, {
        headers: { Authorization: `Bearer ${authState.token}` },
      });
      //const emailId = response.data.id;
  
      // Call the send_mass_email API
      await axios.post('/api/send-mass-email/', { campaign_id: newEmail.campaign, identity_id: newEmail.identity, subject: newEmail.subject, email_body: newEmail.body_html }, {
        headers: { Authorization: `Bearer ${authState.token}` },
      });
      
      setNewEmail({
        campaign: '',
        identity: '',
        subject: '',
      });
    } catch (error) {
      enqueueSnackbar('Failed to send mass email. Please try again.', { variant: 'error' });
    }
  };
  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.message) {
          enqueueSnackbar(data.message, { variant: "success" });
        }
      };
    }
    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [socket, enqueueSnackbar]);

  return (
    <Container className={styles.container}>
      <Typography variant="h4">Compose Email</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          select
          label="Campaign"
          name="campaign"
          value={newEmail.campaign}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          {campaigns.map((campaign) => (
            <MenuItem key={campaign.id} value={campaign.id}>
              {campaign.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Email Identity"
          name="identity"
          value={newEmail.identity}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          {identities.map((identity) => (
            <MenuItem key={identity.id} value={identity.id}>
              {identity.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Subject"
          name="subject"
          value={newEmail.subject}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Typography variant="subtitle1" gutterBottom>
          Body (HTML)
        </Typography>
        <TextField
  select
  label="Email Template"
  value={selectedTemplate}
  onChange={(e) => setSelectedTemplate(e.target.value)}
  fullWidth
  margin="normal"
>
  {templates.map((template) => (
    <MenuItem key={template.id} value={template.id}>
      {template.name}
    </MenuItem>
  ))}
</TextField>
<TextField
  name="body_html"
  label="Email HTML"
  value={newEmail.body_html}
  onChange={handleChange}
  fullWidth
  margin="normal"
  multiline="true"
  rows="10"
/>
<br />
<br /> 
        <Button type="submit" variant="contained" className={styles.submitButton}>
          Compose Email
        </Button>
      </form>
      <br />
    </Container>
  );
}

export default ComposeEmail;

