import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Paper, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    paddingTop: 64,
  },
  form: {
    padding: 20,
    maxWidth: 400,
  },
  submitButton: {
    marginTop: 10,
    background: "#6415FF",
  },
});

const Register = () => {
  const styles = useStyles()
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password1 !== password2) {
      alert('Passwords do not match');
      return;
    }
    try {
      const response = await axios.post('/api-auth/registration/', {
        username,
        email,
        password1,
        password2,
      });
      if (response.data && response.data.key) {
        localStorage.setItem('access_token', response.data.key);
        navigate('/dashboard');
        }
        } catch (err) {
        console.error(err);
        }
        };
        
        return (
        <Container className={styles.container}>
          <Container>
        <Paper className={styles.form}>
        <Typography variant="h5">Register</Typography>
        <form onSubmit={handleSubmit}>
        <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        margin="normal"
        />
        <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        />
        <TextField
        type="password"
        label="Password"
        value={password1}
        onChange={(e) => setPassword1(e.target.value)}
        fullWidth
        margin="normal"
        />
        <TextField
        type="password"
        label="Confirm Password"
        value={password2}
        onChange={(e) => setPassword2(e.target.value)}
        fullWidth
        margin="normal"
        />
        <Button
                 variant="contained"
                 color="primary"
                 fullWidth
                 type="submit"
                 className={styles.submitButton}
               >
        Register
        </Button>
        </form>
        </Paper>
        </Container>
        </Container>
        );
        };
        
        export default Register;
