import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import WebSocketContext from '../context/WebSocketContext';

const useStyles = makeStyles({
  container: {
    marginTop: 3,
    paddingTop: 64,
  },
  paper: {
    padding: 3,
  },
  submitButton: {
    marginTop: 10,
    background: "#6415FF",
  },
});

function EmailIdentities() {
  const navigate = useNavigate();
  const styles = useStyles()
  const { authState } = useContext(AuthContext);  
  const { socket } = useContext(WebSocketContext);
  const { token } = authState;
  const [identities, setIdentities] = useState([]);
  const [newIdentity, setNewIdentity] = useState({
    id: '',
    name: '',
    email: '',
    domain:'',
    smtp_host: '',
    smtp_port: '',
    smtp_username: '',
    smtp_password: '',
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
async function fetchIdentities() {
      try {
        const response = await axios.get('/api/email-identities/', {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setIdentities(response.data);
      } catch (error) {
        enqueueSnackbar('Error retrieving Email Identities!', { variant: 'error' });
      }
    }

    fetchIdentities();
  }, [authState.token]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewIdentity((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Convert smtp_port to a number and include the user ID
    const updatedIdentity = {
      ...newIdentity,
      smtp_port: parseInt(newIdentity.smtp_port, 10),
      user: authState.user.username, // Add this line
    };
  
    try {
      if (updatedIdentity.id) {
        const response = await axios.put(`/api/email-identities/${updatedIdentity.id}/`, updatedIdentity, {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setIdentities((prevIdentities) => {
          return prevIdentities.map((identity) => (identity.id === updatedIdentity.id ? response.data : identity));
        });
        enqueueSnackbar('Email identity added successfully!', { variant: 'success' });
      } else {
        const response = await axios.post('/api/email-identities/', updatedIdentity, {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setIdentities((prevIdentities) => [...prevIdentities, response.data]);
        enqueueSnackbar('Email identity edited successfully!', { variant: 'success' });
      }
      
      setNewIdentity({
        id: '',
        name: '',
        email: '',
        domain:'',
        smtp_host: '',
        smtp_port: '',
        smtp_username: '',
        smtp_password: '',
      });
    } catch (error) {
      enqueueSnackbar('Failed to save email identity. Please try again.', { variant: 'error' });
    }
  };

  const handleEdit = (identity) => {
    setNewIdentity(identity);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/email-identities/${id}/`, {
        headers: { Authorization: `Bearer ${authState.token}` },
      });
      setIdentities(identities.filter((identity) => identity.id !== id));
      enqueueSnackbar('Email identity deleted successfully!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to delete email identity. Please try again.', { variant: 'error' });
    }
  };
  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.message) {
          enqueueSnackbar(data.message, { variant: "success" });
        }
      };
    }
    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [socket, enqueueSnackbar]);
  return (
    <Container className={styles.container}>
      <Typography variant="h4">Email Identities</Typography>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={newIdentity.id} />
        <TextField
          label="Name"
          name="name"
          value={newIdentity.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={newIdentity.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Domain"
          name="domain"
          value={newIdentity.domain}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="SMTP Host"
          name="smtp_host"
          value={newIdentity.smtp_host}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="SMTP Port"
          name="smtp_port"
          value={newIdentity.smtp_port}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="SMTP Username"
          name="smtp_username"
          value={newIdentity.smtp_username}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="SMTP Password"
          name="smtp_password"
          value={newIdentity.smtp_password}
          onChange={handleChange}
          fullWidth
          margin="normal"
          type="password"
        />
        <Button type="submit" variant="contained" className={styles.submitButton}>
          {newIdentity.id ? 'Update' : 'Add'} Email Identity
        </Button>
      </form>
      <Typography variant="h6">Existing Email Identities</Typography>
      <List>
        {identities.map((identity) => (
                    <ListItem key={identity.id}>
                    <ListItemText
                      primary={identity.name}
                      secondary={`${identity.email} (${identity.smtp_host}:${identity.smtp_port})`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(identity)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(identity.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Container>
          );
        }
        
        export default EmailIdentities;
        