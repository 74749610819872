import React, { createContext, useEffect, useState } from 'react';

const WebSocketContext = createContext(null);

const SOCKET_URL = "wss://crm.ecomweb.ro/ws/notifications/";

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(SOCKET_URL);
    ws.addEventListener('open', () => {
      setSocket(ws);
    });

    return () => {
      ws.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext;
