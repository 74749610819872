import React, { useState, useEffect, createContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [authState, setAuthState] = useState(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    return {
      isAuthenticated: token !== null,
      token: token,
      user: user !== null ? JSON.parse(user) : null,
    };
  });

  useEffect(() => {
    localStorage.setItem('token', authState.token);
    localStorage.setItem('user', JSON.stringify(authState.user));
  }, [authState]);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {props.children}
    </AuthContext.Provider>
  );
};
