import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import WebSocketContext from '../context/WebSocketContext';

const useStyles = makeStyles({
  container: {
    marginTop: 3,
    paddingTop: 64,
  },
  paper: {
    padding: 3,
  },
});


const Dashboard = () => {
  const styles = useStyles()
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { token } = authState;
  const [campaignStats, setCampaignStats] = useState([]);
  const { socket } = useContext(WebSocketContext);
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = useCallback(async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get('/api/tracking/campaigns/', config);
      setCampaignStats(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [token]);
  
  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    fetchData();
    const timer = setInterval(() => {
      fetchData();
    }, 5 * 60 * 1000); // Refresh every 5 minutes
  
    return () => {
      clearInterval(timer);
    };
  }, [token, navigate, fetchData]);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.message) {
          enqueueSnackbar(data.message, { variant: "success" });
        }
      };
    }
    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [socket, enqueueSnackbar]);

  const handleRefresh = () => {
    fetchData();
    enqueueSnackbar(`Data refreshed!`, { variant: 'info' });
  };
  return (
    <Container className={styles.container}>
      <Typography variant="h4">Campaign Stats</Typography>
      <Button
        onClick={handleRefresh}
        startIcon={<RefreshIcon />}
        sx={{ marginLeft: 1, marginBottom: 3 }}
      >
        Refresh
      </Button>
      <Paper className={styles.paper} >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Campaign</TableCell>
                <TableCell align="right">Emails Sent</TableCell>
                <TableCell align="right">Emails Opened</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignStats.map((campaign) => (
                <TableRow key={campaign.id}>
                  <TableCell component="th" scope="row">
                    {campaign.campaign_name}
                  </TableCell>
                  <TableCell align="right">{campaign.sent_count}</TableCell>
                  <TableCell align="right">{campaign.opened_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default Dashboard;
