import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../context/AuthContext';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import WebSocketContext from '../context/WebSocketContext';

const useStyles = makeStyles({
  container: {
    marginTop: 3,
    paddingTop: 64,
  },
  paper: {
    padding: 3,
  },
  submitButton: {
    marginTop: 10,
    background: "#6415FF",
  },
});

function Campaigns() {
  const navigate = useNavigate();
  const styles = useStyles()
  const { authState } = useContext(AuthContext);
  const { socket } = useContext(WebSocketContext);
  const { token } = authState;
  const [campaigns, setCampaigns] = useState([]);
  const [newCampaign, setNewCampaign] = useState({ name: '', email_list: '' });
  const [editingCampaignId, setEditingCampaignId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
async function fetchCampaigns() {
      try {
        const response = await axios.get('/api/campaigns/', {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setCampaigns(response.data);
      } catch (error) {
        enqueueSnackbar('Error retrieving campaingns!', { variant: 'error' });
      }
    }

    fetchCampaigns();
  }, [authState.token]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewCampaign((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (editingCampaignId) {
        // Edit the existing campaign
        const response = await axios.put(`/api/campaigns/${editingCampaignId}/`, newCampaign, {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setCampaigns((prevCampaigns) => {
          return prevCampaigns.map((campaign) => (campaign.id === editingCampaignId ? response.data : campaign));
        });
        enqueueSnackbar('Campaign updated successfully!', { variant: 'success' });
      } else {
        // Create a new campaign
        const response = await axios.post('/api/campaigns/', newCampaign, {
          headers: { Authorization: `Bearer ${authState.token}` },
        });
        setCampaigns((prevCampaigns) => [...prevCampaigns, response.data]);
        enqueueSnackbar('Campaign created successfully!', { variant: 'success' });
      }
      setNewCampaign({ name: '', email_list: '' });
      setEditingCampaignId(null);
    } catch (error) {
      enqueueSnackbar('Failed to create/update campaign. Please try again.', { variant: 'error' });
    }
  };
  const handleEdit = (campaign) => {
    setNewCampaign({
      name: campaign.name,
      email_list: campaign.email_list,
    });
    setEditingCampaignId(campaign.id);
  };

  const handleDelete = async (campaignId) => {
    try {
      await axios.delete(`/api/campaigns/${campaignId}/`, {
        headers: { Authorization: `Bearer ${authState.token}` },
      });
      setCampaigns(campaigns.filter((campaign) => campaign.id !== campaignId));
      enqueueSnackbar('Campaign deleted successfully!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to delete campaign. Please try again.', { variant: 'error' });
    }
  };
  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.message) {
          enqueueSnackbar(data.message, { variant: "success" });
        }
      };
    }
    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [socket, enqueueSnackbar]);
  return (
    <Container className={styles.container}>
      <Typography variant="h4">Campaigns</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Campaign Name"
          name="name"
          value={newCampaign.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email List (comma separated)"
          name="email_list"
          value={newCampaign.email_list}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <Button type="submit" variant="contained" className={styles.submitButton}>
        {editingCampaignId ? 'Update Campaign' : 'Add Campaign'}
        </Button>
      </form>
      <Typography variant="h6">Existing Campaigns</Typography>
      <List>
        {campaigns.map((campaign) => (
          <ListItem key={campaign.id}>
            <ListItemText primary={campaign.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(campaign)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(campaign.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default Campaigns;
