import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const useStyles = makeStyles({
  appbar: 
  {background: "#6415FF",},
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

const Navbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const { isAuthenticated } = authState;

  const handleLogout = () => {
    setAuthState({
      isAuthenticated: false,
      token: null,
      user: null,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <Link to="/dashboard" className={classes.link}>
            CRM
          </Link>
        </Typography>
        {isAuthenticated ? (
          <>
            <Button color="inherit" component={Link} to="/dashboard">
                Dashboard
            </Button>
            <Button color="inherit" component={Link} to="/campaigns">
              Campaigns
            </Button>
            <Button color="inherit" component={Link} to="/email-identities">
              Email Identities
            </Button>
            <Button color="inherit" component={Link} to="/template-editor">
              Template Editor
            </Button>
            <Button color="inherit" component={Link} to="/compose-email">
              Compose Email
            </Button>
            <Button color="inherit" component={Link} to="/track">
              Track Emails
            </Button>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
            <Button color="inherit" component={Link} to="/register">
              Register
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
